



























































































































































import Vue from 'vue';
import {
  mdiFormTextboxPassword,
  mdiEyeOff,
  mdiEye,
  mdiCheckCircle,
  mdiAlertCircle,
} from '@mdi/js';

import i18n from '@/i18n';
import { SignIn } from '@/router/routes';
import { mapMutations } from 'vuex';
import { ADD_NOTIFICATION } from '../store/notifications';
import { ListUser } from '../store/users/types';

function mandatoryRule(value: string) {
  return !!value || i18n.t('mandatoryField');
}

export default Vue.extend({
  data: () => {
    return {
      mdiFormTextboxPassword,
      mdiEyeOff,
      mdiEye,
      mdiCheckCircle,
      mdiAlertCircle,
      isUserLoading: false,
      isValid: false,
      isLoading: false,
      user: {} as ListUser,
      password: '',
      passwordConfirmation: '',
      passwordToggle: false,
      confirmPasswordToggle: false,
      passwordConfirmationError: '',
      passwordRules: [mandatoryRule],
      passwordConfirmationRules: [mandatoryRule],
      isSuccess: null,
      alert: {
        message: '',
      },
    };
  },
  methods: {
    ...mapMutations({
      addNotification: ADD_NOTIFICATION,
    }),
    /**
     * Check if the confirm password equal the password,
     * - Equal: return null
     * - Not equal: return an error message
     *
     * @param {string} value The data payload of the Event input
     */
    onInputConfirmPassword(value: string): void {
      this.passwordConfirmationError = '';

      // one of the fields is not filled, nothing to do
      if (this.password === '' || this.passwordConfirmation === '') return;

      this.passwordConfirmationError =
        this.passwordConfirmation === this.password
          ? ''
          : i18n.t('users.changePassword.confirmPasswordError').toString();
    },
    toHome() {
      this.$router.push({
        name: SignIn.name,
        params: { lang: this.$i18n.locale },
      });
    },
    async confirmUserRegistration(user: ListUser) {
      try {
        this.user = user;
        this.isLoading = true;
        this.isSuccess = await this.$http.users.selfRegistrationConfirmation({
          token: this.$route.params.token,
          password: this.passwordConfirmation,
        });
        if (this.isSuccess === false) {
          this.addNotification({
            message: 'users.userLoginAlreadyUsed',
            color: 'error',
          });
        } else {
          this.alert.message = this.$i18n
            .t('selfRegistration.success', {
              login: this.user.login,
              email: this.user.email,
            })
            .toString();
          return;
        }
      } catch (error) {
        // switch (error.statusCode) {
        //   case 409:
        //     this.addNotification({
        //       message: this.$t("users.userLoginAlreadyUsed"),
        //       color: "error",
        //     });
        //     break;
        //   case 500:
        //     this.addNotification({
        //       message: error.message,
        //       color: "error",
        //     });
        //     break;
        // }
      } finally {
        this.isLoading = false;
      }
    },
  },
  async beforeMount() {
    this.isUserLoading = true;
    this.user = await this.$http.users.getUserToRegister({
      token: this.$route.params.token,
    });
    this.isUserLoading = false;
  },
  computed: {
    Password() {
      return i18n.t('users.newPassword');
    },
    ConfirmPassword() {
      return i18n.t('users.changePassword.confirmPassword');
    },
  },
  watch: {
    '$route.params.token': function (newToken, oldToken) {
      if (newToken !== oldToken) this.$forceUpdate();
    },
    '$i18n.locale'() {
      (this.$refs.confirmSelfRegistration as any).validate();
    },
  },
});
